li {
  text-decoration: none;
}

ul {
  margin: 0;
  text-align: center;
  padding-inline-start: 0px
}

a {
  text-decoration: none;
  color: aliceblue;
}

.nav-achors {
  position: fixed;
  display: block;
  width: 100%;
  margin: 0px;
  padding: 0px;
}

.nav-anchor {
  /* padding: 5px 20px; */
  /* margin: 5px auto; */
  display: inline-block;
  letter-spacing: 1px;
  padding: 0px;
  width: 33%;
  margin-top: 5px;
}

.work {

}

.navbar{
  background-color: black;
}