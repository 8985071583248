* {
  box-sizing: border-box;
  font-family: 'Catamaran', sans-serif;
  /* -webkit-overflow-scrolling: touch; */
}

body {
  margin: 0;
}

.social-media-links {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  list-style-type: none;
}

footer {
  border-top: 1px lightgray solid;
}

footer h2 {
  text-align: center;
}
footer p {
  text-align: center; 
}

.footer-links {
  display: block;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
}

.footer-links a {
  text-decoration: none;
  color: #1d2636;
}
