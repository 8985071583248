#aboutMe-section {
  width: 80%;
  font-size: 18px;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 5px 40px 0 rgba(0,0,0,.45);
  padding: 3px;
}

#aboutMe-section p {
  padding-left: 15px;
  padding-right: 15px;
}